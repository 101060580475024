.bingo-3d-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(45deg, #1a5f7a, #57c5b6);
  perspective: 1000px;
}

.bingo-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bingo-balls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.bingo-ball {
  width: 50px;
  height: 50px;
  background: radial-gradient(circle at 30% 30%, #ffffff, #ff6b6b);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  font-weight: bold;
  font-size: 1.2em;
  color: #1a5f7a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.bingo-card {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  transform: rotateX(10deg);
  transition: transform 0.3s ease;
  max-width: 400px;
  width: 100%;
}

.bingo-card:hover {
  transform: rotateX(0deg);
}

.bingo-card h2 {
  text-align: center;
  color: #1a5f7a;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #1a5f7a;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #57c5b6;
  border-radius: 5px;
  font-size: 1em;
}

.radio-group {
  display: flex;
  justify-content: space-around;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.1em;
  font-weight: bold;
}

button[type="submit"]:hover {
  background-color: #ff4757;
}

button[type="submit"]:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}